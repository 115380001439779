import React, { useEffect } from 'react';
import BreadcrumbComponent from '../../../components/breadcrumb/Breadcrumb.component';
import { Breadcrumb } from '../../../components/breadcrumb/breadcrumb.interface';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { ROUTES } from '../../../resources/routes-constants';
import { Link, NavLink } from 'react-router-dom';
import { getAdminKitchensAction } from '../actions/kitchen.actions';
import { ArrayFromNumber } from '../../../utility/helpers/array-from-number.helper';
import { SORT_DIRECTION } from '../../../utility/enums/sort-direction.enum';
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum';
import { useLoading } from '../../../provider/IsLoading.provider';
import { useTranslation } from 'react-i18next';
import { NoResultsComponent } from '../../../components/no-results/NoResults.component';
import { IKitchen } from '../interface/kitchen.interface';
import { Translation } from '../../../utility/helpers/translation.helper';

const KitchensListPage: React.FC<any> = () => {
	const assets = process.env.REACT_APP_ASSETS_URL;
	const { t } = useTranslation();
	const breadcrumb: Breadcrumb = {
		title: 'Categories',
		links: [
			{ name: 'Dashboard', path: '/' },
			{ name: 'Categories', path: '/kitchen/list' }
		]
	};
	const dispatch = useAppDispatch();
	const { setLoading } = useLoading();
	const { kitchens, status } = useAppSelector((state) => state.kitchen);

	useEffect(() => {
		if (status == REDUX_STATUS.SUCCEEDED || status == REDUX_STATUS.FAILED) {
			setLoading(false);
		}
	}, [setLoading, status]);

	useEffect(() => {
		if (status == REDUX_STATUS.IDLE) {
			setLoading(true);
			dispatch(
				getAdminKitchensAction({
					offset: 1,
					limit: 12,
					orderBy: 'createdAt',
					orderDirection: SORT_DIRECTION.ASC
				})
			);
		}
	}, [dispatch, kitchens, setLoading, status]);

	return (
		<div className="container-fluid">
			<div className="row">
				<BreadcrumbComponent breadcrumb={breadcrumb} />
			</div>

			<div className="row">
				<div className="col-lg-12">
					<div>
						<div className="card">
							<div className="card-header border-0">
								<div className="row g-4">
									<div className="col-sm-auto">
										<div>
											<Link to={ROUTES.CATEGORIES_CREATE} className="btn btn-primary" id="addproduct-btn">
												<i className="ri-add-line align-bottom me-1"></i> {t("Add")}
											</Link>
										</div>
									</div>
									<div className="col-sm">
										<div className="d-flex justify-content-sm-end">
											<div className="search-box ms-2">
												<input type="text" className="form-control" id="searchProductList" placeholder={t("Search_Placeholder")} />
												<i className="ri-search-line search-icon"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
							{kitchens.length > 0 &&
								<div className="card-body">
									<div id="table-product-list-all" className="table-card">
										<div className="table-responsive">
											<table className="table align-middle table-nowrap mb-0">
												<thead className="table-light">
													<tr>
														<th scope="col">{t("ID")}</th>
														<th scope="col">{t('Name')}</th>
														<th scope="col">{t('Rating')}</th>
														<th scope="col">{t('IsActive')}</th>
														<th scope="col">{t('Actions')}</th>
													</tr>
												</thead>
												<tbody>
													{kitchens.map((kitchen: IKitchen) =>

														<tr key={kitchen._id}>
															<td>
																<div className="d-flex align-items-center">
																	<div className="flex-grow-1">
																		<p className="text-muted mb-0">
																			<a href={'/kitchens/view/' + kitchen._id} className="text-body">
																				{kitchen._id}
																			</a>
																		</p>
																	</div>
																</div>
															</td>
															<td>
																<div className="d-flex align-items-center">
																	<div className="flex-shrink-0 me-3">
																		<div className="avatar-sm bg-light rounded p-1">
																			<img src={assets + kitchen.logo} alt="" className="img-fluid d-block" />
																		</div>
																	</div>
																	<div className="flex-grow-1">
																		<h5 className="fs-14 mb-1">
																			<a href={'/kitchens/view/' + kitchen._id} className="text-body">
																				{Translation(kitchen.name)}
																			</a>
																		</h5>
																	</div>
																</div>
															</td>
															<td>
																<div className="flex-grow-1">
																	<div className="fs-16 align-middle text-warning">
																		{ArrayFromNumber(kitchen.rating).map((item: any) => (
																			<i key={item} className="ri-star-fill"></i>
																		))}
																		{ArrayFromNumber(5 - kitchen.rating).map((item: any) => (
																			<i key={item} className="ri-star-line"></i>
																		))}
																	</div>
																</div>
															</td>
															<td>{kitchen.isActive
																? <span className="badge bg-success-subtle text-success text-uppercase">Active</span>
																: <span className="badge bg-danger-subtle text-danger text-uppercase">Inactive</span>}</td>
															<td>
																<ul className="list-inline hstack gap-2 mb-0">
																	<li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="View">
																		<NavLink to={'/kitchens/view/' + kitchen._id} className="text-primary d-inline-block">
																			<i className="ri-eye-fill fs-16"></i>
																		</NavLink>
																	</li>
																</ul>
															</td>
														</tr>
													)}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							}
							{!kitchens.length && <NoResultsComponent />}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default KitchensListPage;
