import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { loginAction, signupAction } from '../action/auth.action';
import { IAuth } from '../interface/Auth.interface';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import customAxios from '../../../utility/customAxios';
import { REDUX_STATUS } from '../../../utility/enums/redux-status.enum';

export const SuccessAlert = withReactContent(Swal);

interface DataReducer {
	contents: IAuth;
	status: string;
}

const initialState: DataReducer = {
	contents: {} as IAuth,
	status: 'idle',
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setAuth(state: DataReducer, action: PayloadAction<any>) {
			state.contents = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(loginAction.pending, (state) => {
				state.status = REDUX_STATUS.PENDING;
			})
			.addCase(loginAction.fulfilled, (state, action) => {
				state.status = REDUX_STATUS.SUCCEEDED;
				localStorage.setItem('TK', JSON.stringify(action.payload.data));
				state.contents = action.payload.data;
				customAxios.interceptors.request.use(
					async (config) => {
						config.headers['Authorization'] = `Bearer ${action.payload.data.accessToken}`;
						return config;
					},
					(error) => {
						return Promise.reject(error);
					}
				);
			})
			.addCase(loginAction.rejected, (state, action: any) => {
				SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, text: action.payload.errors && action.payload.errors.length > 0 ? action.payload.errors[0].message : '', showConfirmButton: !0, timer: 5000 });
				state.status = REDUX_STATUS.FAILED;
			})

			.addCase(signupAction.pending, (state) => {
				state.status = REDUX_STATUS.PENDING;
			})
			.addCase(signupAction.fulfilled, (state, action: any) => {
				state.status = REDUX_STATUS.SUCCEEDED;
				localStorage.setItem('TK', JSON.stringify(action.payload.data));
				state.contents = action.payload.data;
				customAxios.interceptors.request.use(
					async (config) => {
						config.headers['Authorization'] = `Bearer ${action.payload.data.accessToken}`;
						return config;
					},
					(error) => {
						return Promise.reject(error);
					}
				);
			})
			.addCase(signupAction.rejected, (state, action: any) => {
				SuccessAlert.fire({ position: 'center', icon: 'error', title: action.payload.message, text: action.payload.errors && action.payload.errors.length > 0 ? action.payload.errors[0].message : '', showConfirmButton: !0, timer: 5000 });
				state.status = REDUX_STATUS.FAILED;
			});
	},
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
